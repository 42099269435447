@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');
*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.tool-height-decresed {
    max-height: calc(100vh - 220px) !important;
}
.already {
    color: #d0d0d0;
    font-size: 13px;
    text-align: center;
    margin-bottom: 15px;
}
p.already span {
    text-decoration: underline;
    cursor: pointer;
}

#for-mobile {
    z-index: 999;
    position: absolute;
    top: 130px;
    left: 85px;
    right: 30px;
    background: #fff;
    padding: 20px;
    text-align: center;
}
div#tool-off {
    display: none;
}
div#inputText p {
    margin-bottom: 12px;
}

#body {
    min-height: 100vh;
    transition: 0.5s;
    display: flex;
    align-items: center;
}
.bodyColor1 {
    background: #1dd1a1;
}
.bodyColor2 {
    background: #ff6b6b;
}
.bodyColor3 {
    background: #2e86de;
}
.bodyColor4 {
    background: #f368e0;
}
.bodyColor5 {
    background: #ff9f43;
}

.colors 
{
    position: absolute;
    right: 0;
    width: 75px;
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-left: 3px solid #333;
        top: 0;
        z-index: 99999;
}
.colors span 
{
    position: relative;
    width: 30px;
    height: 30px;
    margin: 15px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
}
.colors span.active 
{
    border: 3px solid #333;
}
.colors span::before 
{
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    rotate: 45deg;
    translate: -36px;
    opacity: 0;
    transition: 0.5s;
    border-top: 3px solid #333;
    border-right: 3px solid #333;
}
.colors span.active::before 
{
    opacity: 1;
}

div#tool {
    width: 100%;
    z-index: 999;
}

div#row {
    display: flex;
    background: transparent;
    margin: 0 auto;
    width: 100%;
    border-top: 0;
    position: relative;
}
.column {
    width: 100%;
    padding: 25px 0px 0px 0px;
    flex-basis: 100%;
    overflow: hidden;
}
#left-column {
    border-right: 2px solid #d5d5d5;
    position: relative;
}
#right-column {
    border-left: 2px solid #d5d5d5;
    position: relative;
}
textarea {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    border: 0;
    color: #f0f0f0 !important;
    font-size: 16px;
    min-height: 300px;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #f0f0f0;
}
textarea:focus-visible, #inputText:focus-visible {
    border: 0;
    outline: unset;
}

div#inputText {
    min-height: calc(100vh - 410px);
    padding: 0 20px 0 25px;
    max-height: calc(100vh - 280px);
    overflow-y: scroll;
    width: 105%;
    margin-bottom: 25px;
}
div#InputWrapper {
    width: 100%;
    overflow: hidden;
}
button#paraphrase {
    background: #fff;
    color: #000;
    border: 0;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 15px;
    cursor: pointer;
    font-weight: 600;
    width: 142px;
    height: 39px;
    position: static;
    right: 8px;
    top: 10px;
}

button#paraphrase1 {
    width: 200px;
    height: 50px;
    background: #333;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 1em;
    font-weight: 500;
    color: rgba(255,255,255,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
}

button#paraphrase1::before 
{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1dd1a1;
  transform: scaleX(0);
  transition: transform 0.5s ease-in-out;
  transform-origin: right;
}
button#paraphrase1:hover::before
{
  transform: scaleX(1);
  transform-origin: left;
}
button#paraphrase1 span 
{
  display: inline-block;
  z-index: 2;
  line-height: 1em;
  transition: 0.5s ease-in-out;
}
button#paraphrase1:hover span 
{
  color: #333;
}
button#paraphrase1 i 
{
  position: relative;
  display: inline-block;
  width: 2px;
  height: 30px;
  background: rgba(255,255,255,0.5);
  background: #1dd1a1;
  transition: 0.5s, width 0.5s, height 0.5s;
  transition-delay: 0s,0.5s,1s;
}
button#paraphrase1:hover i 
{
  width: 30px;
  height: 2px;
  background: #333;
  transition-delay: 0s,1s,0.5s;
}
button#paraphrase1 i::before 
{
  content: '';
  position: absolute;
  top: 0.5px;
  right: 0px;
  width: 50%;
  height: 2px;
  background: transparent;
  transform-origin: right;
  rotate: 0;
  transition: 1s;
}
button#paraphrase1:hover i::before 
{
  background: #333;
  rotate: 45deg;
  transition-delay: 1.5s;
}

button#paraphrase1 i::after 
{
  content: '';
  position: absolute;
  top: -0.5px;
  right: 0px;
  width: 50%;
  height: 2px;
  background: transparent;
  transform-origin: right;
  rotate: 0;
  transition: 1s;
}
button#paraphrase1:hover i::after 
{
  background: #333;
  rotate: -45deg;
  transition-delay: 1.5s;
}
p#loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
}
p#loader img {
    width: 50px;
    display: block;
    margin: 0 auto;
    margin-top: 20%;
}
span#quota {
    font-size: 12px;
    display: flex;
    width: calc(100% - 160px);
}
div#bottom-left {
    position: relative;
    background-color: rgb(0 0 0 / 12%);
    float: left;
    width: 100%;
    padding: 10px;
    min-height: 58px;
    display: flex;
    align-items: center;
}
div#result {
    min-height: calc(100vh - 410px);
    padding: 0 20px 0 25px;
    overflow-y: scroll;
    width: calc(100% + 20px);
    max-height: calc(100vh - 280px);
    margin-bottom: 0px;
}
div#result:focus-visible {
    outline: none;
}
#result-warpper {
    width: 100%;
    overflow: hidden;
}
div#right-bottom {
    position: absolute;
    bottom: 0;
    background-color: rgb(0 0 0 / 12%);
    float: left;
    width: 100%;
    padding: 10px;
    color: #fff;}
#top-bar {
width: 100%;
    margin: 0 auto;
    background-color: rgb(0 0 0 / 12%);
    border-bottom: 1px solid #424242;
    padding: 10px 10px 5px 10px;
    position: relative;
    height: 45px;
}
span#tooltip-topbar {
    position: absolute;
    right: 8px;
    top: 4px;
}
span#mode-dropdown, span#language-dropdown {
    display: block;
    position: absolute;
    z-index: 9999999;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    margin-top: 9px;
    background: #fff; 
}
span#language-dropdown {
    left: 200px;
}
span#mode-dropdown span.tooltip, span#language-dropdown span {
    display: block;
    cursor: pointer;
    padding: 10px 10px;
    color: #333;
    font-size: 14px;
    line-height: 14px;
}
span#mode-dropdown span.tooltip:hover, span#language-dropdown span:hover {
    background: #f1f1f1;
}
span#mode button, span#language button {
    border: none;
    background: transparent;
    width: fit-content;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    color: #fff;
}
span#mode button:after, span#language button:after {
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: 0.155em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
#top-bar svg {
    cursor: pointer;
    color: #fff;
}
#mode-title {
  font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-right: 6px;
}
span#language {
    margin-left: 20px;
}
p#small-text-left-bottom {
    position: absolute;
    top: -15px;
    font-size: 11px;
    margin-bottom: 0;
}
.Toastify__toast-container {
    z-index: 999999999 !important;
}
#tool-intro {
    display: flex;
    align-items: center;
    height: 250px;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 18px;
    line-height: 32px;
}
#tool-intro button {
    display: block;
    margin-top: 25px;
    padding: 4px 20px;
    font-size: 16px;
    border-radius: 4px;
}
/*****popup****/
  div#popup, div#popup-filter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    align-items: center;
    transition: 1s;
    background: rgba(0,0,0,0.5);
    display: none;
}
.login-popup-show {
    display: flex !important;
}
div#login-form, div#filter-form {
    background: #000;
    padding: 35px;
    box-shadow: 15px 15px rgb(0 0 0 / 10%);
    max-width: 380px;
    margin: 0 auto;
    width: 100%;
    position: relative;
}
div#filter-form {
    background: rgba(0,0,0,1);
    text-align: center;
}
.inputBox {
    position: relative;
    margin-bottom: 20px;
}
div#filter-form .inputBox {
    text-align: left;
}
div#filter-form label {
    width: 100%;
    display: block;
    margin-bottom: 3px;
}
div#filter-form select {
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    color: #555;
}
div#filter-form button:disabled {
    background: #69bea0;
    cursor: no-drop;
}

form input {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    padding: 10px 0;
    border-bottom: 2px solid #333;
    color: #999;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1em;
}
.form-control {
    border-radius: unset !important;
}

form button {
    width: 100%;
    height: 50px;
    background: #fff;
    color: #000;
    border: 0px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 15px;
}
div#login-form button {
    background: #767474 !important;
    color: #fff;
    font-size: 15px;
}
.inputBox.accordian-input input {
border: 1px solid #e0e0e0;
    padding-left: 10px;
    text-transform: lowercase;
    font-weight: 400;
    font-size: 13px;
    border-radius: 4px;
    color: #e5e5e5;
        background: rgba(0, 0, 0, 0.12);
}
.inputBox.accordian-input label {
    font-size: 14px;
    margin-bottom: 6px;
    display: block;
    font-weight: 500;
}
.acc-form-btn {
    margin-top: 0;
    background: #4a4a4a;
    font-size: 14px;
    height: 45px;
    border-radius: 4px;
    color: #fff;
    border: 0;
    cursor: pointer;
}
ul.acc-ul {
    margin: 10px 0 0 20px;
}
ul.acc-ul li {
    font-size: 13px;
    margin-bottom: 4px;
}
#text {
    display: block;
    color: #999;
    font-weight: 500;
    padding: 5px 0;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.75em;
}
#waiting, #verfied {
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
}
#waiting img {
    width: 23px;
    vertical-align: bottom;
    margin-right: 3px;
}
span.tooltiptext.warning-count {
    width: 200px;
    left: 0;
}
#delete-acc {
    color: #dbdbdb;
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

/*****sidebar*****/
.navigation
{
    position: fixed;
    inset: 20px;
    width: 67px;
    background: #ffffff;
    border-left: 4px solid #fefefe;
    transition: 0.5s;
    overflow: hidden;
    border-radius: 50px;
    box-shadow: 15px 15px 25px rgb(0 0 0 / 5%);
    z-index: 99999;
    margin-top: 32px;
}

.navigation.active
{
    width: 312px !important;
    border-radius: 20px;
}
.navigation ul
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.navigation ul li
{
    position: relative;
    width: 60px;
    width: 100%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    list-style: none;
}
.navigation ul li:hover
{
    background: #fff;
    color: #222;
}
.navigation ul li:nth-child(1)
{
    top: 20px;
    margin-bottom: 40px;
    background: none;
}

.navigation ul li:not(:first-child):hover::before 
{
    content: '';
    position: absolute;
    top: -20px;
    right: 0;
    width: 20px;
    height: 20px;
    background: transparent;
    border-bottom-right-radius: 20px;
    box-shadow: 7.5px 7.5px 0 7.5px #fff;
}
.navigation ul li:not(:first-child):hover::after 
{
    content: '';
    position: absolute;
    bottom: -20px;
    right: 0;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-right-radius: 20px;
    box-shadow: 7.5px -7.5px 0 7.5px #fff;
}
.navigation ul li a
{
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: #213e6b;
}
.navigation ul li:hover:not(:first-child) a 
{
    color: #365fa1;
}
.navigation ul li a .icon
{
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 70px;
    text-align: center;
}
.navigation ul li a .icon ion-icon
{
    font-size: 1.75em;
}
.navigation ul li a .title
{
    position: relative;
    display: block;
    padding: 0 10px;
    height: 60px;
    line-height: 55px;
    text-align: start;
    white-space: nowrap;
}
.toggle
{
  position: absolute;
    bottom: 12px;
    right: 10px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 15%);
}
.toggle::before 
{
    content: '';
    position: absolute;
    width: 26px;
    height: 3px;
    border-radius: 2px;
    background: #365fa1;
    transform: translateY(-5px);
    transition: 1s;
}
.toggle::after
{
    content: '';
    position: absolute;
    width: 26px;
    height: 3px;
    border-radius: 2px;
    background: #365fa1;
    transform: translateY(5px);
    transition: 1s;
}
.navigation.active .toggle::before 
{
    transform: translateY(0px) rotate(-405deg);
}
.navigation.active .toggle::after 
{
    transform: translateY(0px) rotate(225deg);
}

/******h1 + description*****/
.container-des 
{
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    transition: 0.5s;
}
.container-des::before 
{
    content: '';
    position: absolute;
    bottom: 85px;
    width: 40px;
    height: 40px;
    background: #37444b;
    border-radius: 5px;
    opacity: 0;
    transform: rotate(45deg);
    transition: 0.5s;
}
.container-des.active::before 
{
    opacity: 1;
    transition-delay: 0.5s;
}
.container-des.active 
{
      transition-delay: 0.5s;
      z-index: 99999999;
}
.container-des .content 
{
    width: 400px;
    background: #37444b;
    padding: 40px;
    color: #fff;
    opacity: 0;
    transition: 0.5s;
    margin-bottom: 100px;
}
.container-des.active .content 
{
    opacity: 1;
    transition-delay: 0.5s;
}
.toggleBtn 
{
    position: absolute;
    bottom: 5px;
    min-width: 60px;
    height: 60px;
    background: #ffc107;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}
.container-des.active .toggleBtn 
{
    bottom: 15px;
    background: #ff5a57;
    transition-delay: 0.5s;
}
.toggleBtn::before 
{
   content: 'i';
    font-size: 3em;
    color: #fff;
    font-style: italic;
    font-family: none;
    font-weight: 800;
}
.container-des.active .toggleBtn:before 
{
    content: '✕';
    font-style: normal;
    font-size: 2em;
    font-weight: 600;
}


/*******swicth to login *****/

.toggle-login {
    display: block;
    position: relative;
    background: none;
    margin: 0 0 0.5em 0;
    font-size: 14px;
}

.toggle-login input[type="checkbox"] {
    opacity: 0 !important;
}

.toggle-login input[type="checkbox"] + label {
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 3em;
    color: #000;
}

.toggle-login input[type="checkbox"] + label:before {
    -webkit-transition: background-color 0.4s ease;
    -moz-transition: background-color 0.4s ease;
    transition: background-color 0.4s ease;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: absolute;
    top: 0.25rem;
    right: -2px;
    content: '';
    width: 2.25rem;
    height: 0.75rem;
    background-color: #767676;
    border-radius: 0.5rem;
}

.toggle-login input[type="checkbox"]:checked + label:before {
    background-color: #00b5ad;
}

.toggle-login input[type="checkbox"] + label:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75),;
    -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    position: absolute;
    top: 0rem;
    right: 15px;
    width: 1.25rem;
    height: 1.25rem;    
    content: '';
    font-size: 0.75em;
    background-color: #fff;
    border-radius: 500rem;
    border: 2px solid #767676;
}

.toggle-login input[type="checkbox"]:checked + label:after {
    transform: translateX(1.2rem);
    border: 2px solid #00b5ad;
}

.toggle-login.disabled {
    opacity: 0.5;
    pointer-events: none;
}
div#right-bottom span {
    float: right;
}
div#right-bottom span svg {
    vertical-align: -webkit-baseline-middle;
    cursor: pointer;
}

/******tooltip*******/
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 70px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 135%;
  margin-left: -60px;
  font-size: 13px;
}

span#mode-dropdown span.tooltiptext {
  visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 128%;
    margin-left: -60px;
    font-size: 14px;
    bottom: unset;
    line-height: 18px;
}

span#mode-dropdown span.tooltiptext::after {
  content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
        left: unset;
}  
span#mode-dropdown .tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

span#daily-credit .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 29%;
    margin-left: -60px;
    font-size: 13px;
}
span#daily-credit:hover .tooltiptext {
  visibility: visible;
}
/******toast****/
.toast{
    position: fixed;
    top: 5px;
    right: 5px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.82);
    padding: 15px 35px 15px 25px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
    z-index: 999999;
    width: 100%;
    max-width: 250px;
}

.toast.active{
  display: block;
}
.toast-content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.toast-check{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background-color: #40f467;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
}
.message{
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}
.message-text{
  font-size: 20px;
  font-weight: 600;
}
.text-1{
  color: #333;
}
.text-2{
      color: #fff;
    font-weight: 400;
    font-size: 14px;
}
.message-text svg {
    vertical-align: middle;
}
.toast-close{
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
}
.toast-close:hover{
  opacity: 1;
}
.progress{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  background: #ddd;
}
.progress::before{
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #2a617b;
}
.progress.active::before{
  animation: progress 5s linear forwards;
}
@keyframes progress {
  100%{
    right: 100%;
  }
}

span#daily-credit svg {
    font-size: 15px;
    color: #888;
    vertical-align: sub;
}
div#pro-popup {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.8);
    z-index: 999999999;
    transform: translateY(100%) scale(0);
    transition: opacity 0.2s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
    display: flex;
    align-items: center;
}
.open-pro {
  transform: translateY(0) scale(1) !important;
}
div#pro-popup-main {
     background: rgb(0 0 0 / 100%);
    width: 60%;
    padding: 20px;
    border-radius: 20px;
    margin: 0 auto;
    position: relative;
    max-height: 100vh;
}
.toolshow {
    visibility: hidden;
}
div#success-page {
    width: 100%;
    z-index: 999;
    position: absolute;
    height: 100%;
}
div#success-inner {
    display: flex;
    margin: 0 auto;
    width: 100%;
    position: relative;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
        padding-top: 7%;
}
.success-col {
    width: 100%;
}
.success-col.left-side {
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 30px;
}
button#paraphrase:disabled {
    cursor: no-drop;
    opacity: 0.6;
}
.myupgrade {
font-size: 11px;
    color: #fff;
    border-radius: 3px;
    margin-left: 5px;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    background: unset;
}
a#go-to-tool {
    border: 2px solid #777;
    padding: 8px 20px;
    font-size: 15px;
    margin-top: 12px;
    cursor: pointer;
}
.success-col.right-side {
    padding-left: 30px;
    padding-right: 30px;
}
div#success-page h3 {
    font-weight: 600;
    margin-bottom: 6px;
}
div#succ-table {
    margin-top: 30px;
    margin-bottom: 20px;
}
div#table-head {
    display: flex;
    border-top: 2px solid #9b9595;
    border-bottom: 2px solid #9b9595;
    padding-top: 5px;
    padding-bottom: 6px;
        background: rgba(0, 0, 0, 0.8);
}
div#table-head p {
    width: 100%;
}
div#table-head p b {
    font-weight: 500;
}
div#table-content {
    display: flex;
    border-bottom: 2px solid #9b9595;
    padding-top: 10px;
    padding-bottom: 10px;
}
div#table-content p {
    width: 100%;
    font-size: 15px;
}
span#pro-close {
    position: absolute;
    top: 16px;
    right: 16px;
}
span#pro-close svg {
    color: #999;
    cursor: pointer;
}
span.quota_login {
    display: inline-block;
    margin-top: 1px;
    font-size: 14px;
    margin-right: 5px;
}
div#pro-popup-inner {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 10px 0 10px;
}
div#first-pro p {
    font-size: 14px;
}
div#first-pro h3 {
    margin: 20px 0 10px;
}
div#pro-popup button {
    padding: 10px 55px;
    background: #3F51B5;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}
div#second-pro {
    height: 93vh;
}
button#checkout-and-portal-button {
    margin-top: 10px !important;
}
div#pro-popup button svg {
    font-size: 16px;
    vertical-align: middle;
    margin-top: -2px;
}
div#pro-counter {
    margin: 5px 0 30px 0;
    font-size: 60px;
    font-weight: 600;
}
div#second-pro ul {
    list-style: none;
    max-width: fit-content;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px;
}
div#second-pro ul li {
   margin-bottom: 6px;
    font-size: 15px;
}
div#plans .plan.active {
    border: 1px solid #f1f1f1 !important;
}
div#plans .plan p {
    font-size: 15px;
}
p#auto-applied {
    font-size: 12px;
    margin-bottom: 7px;
}
p#auto-applied svg {
    font-size: 17px;
    vertical-align: text-top;
    color: #fff;
}
div#selected_plan {
    visibility: hidden;
    margin-top: -14px;
}
.plan.active div#selected_plan {
    visibility: visible !important;
}
div#second-pro ul li svg {
    font-size: 18px;
    vertical-align: sub;
    color: #fff;
}
div#plans {
    display: flex;
    margin-bottom: 25px;
}
div#plans .plan {
    width: 100%;
    border: 1px solid #585656;
    margin: 10px;
    padding: 30px;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
}

div#plans .plan p:nth-child(1) {
    font-size: 15px;
}
p.sub_price {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
}
p.sub_price span {
    text-decoration: line-through;
    color: #999;
}
#subscription select, #onetime select {
    border: 1px solid #e7e7e7;
    padding: 8px;
    border-radius: 3px;
}
div#selected_plan svg {
    color: #2196f3;
}
div#second-pro button {
    width: 150px;
    padding: 6px;
    height: 35px;
    font-size: 13px;
}
div#promocode input[type="text"] {
    width: 150px;
    padding: 5px;
    border: 1px solid #999;
    border-radius: 3px;
}
p#powered-by {
    font-size: 13px;
    margin-top: 20px;
}
div#pro-popup-main h3 {
    font-size: 20px;
    margin-top: 15px;
}
span#backTofirst {
    position: absolute;
    left: 24px;
    top: 24px;
}
span#backTofirst svg {
    color: #999;
    cursor: pointer;
}
div#login-form h3 {
    color: #fff;
    text-align: center;
    font-weight: 600;
    margin: 8px 0 25px 0;
        font-size: 20px;
}
span.closeLogin {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #929795;
    cursor: pointer;
}
.form-footer {
    margin-top: 26px;
    color: #e2e3e4;
    font-size: 13px;
}
span.right-foot {
    float: right;
    cursor: pointer;
}
span.right-foot svg {
    font-size: 16px;
    vertical-align: text-top;
}
#mode-title svg {
  cursor: auto;
    vertical-align: sub;
    font-size: 21px;
}
span.quota_login:hover {
    text-decoration: underline;
    cursor: pointer;
}

p#optimize {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    top: 0;
    left: 0;
    color: #fff;
    text-align: center;
}
p#optimize img {
    width: 50px;
    margin-top: 20%;
}
p#optimize span {
    display: block;
}

div#progressbarContainer {
    width: 330px;
    height: 150px;
    margin: 0 auto;
    margin-top: 80px !important;
    text-align: center;
}
div#progressbarContainer svg circle {
    filter: none !important;
}

div#discount-top {
position: fixed;
    bottom: 0;
    right: 0;
    background: #212529;
    width: 335px;
    padding: 15px;
    color: #fff;
    text-align: left;
    z-index: 999999;
    border-radius: 10px;
}
div#discount-top .discount-icon {
    position: absolute;
    top: 26px;
    left: 13px;
}
div#discount-top .discount-icon svg {
    font-size: 50px;
}
#discount-close {
    position: absolute;
    top: 6px;
    right: 6px;
}
div#discount-top p {
    font-size: 13px;
    padding-left: 60px;
    margin-bottom: 0;
}
.tooltip {
    opacity: 1;
}
div#discount-top p span {
    text-decoration: underline;
    cursor: pointer;
}
#discount-close svg {
    cursor: pointer;
}
span.tooltip.blocked {
    cursor: no-drop !important;
    color: #727272 !important;
}


/* Accordian */
.MuiCollapse-wrapperInner p, p.MuiTypography-root {
    font-family: 'Poppins', sans-serif !important;
}

#delete-popup {
   position: absolute;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.8);
    z-index: 999999;
    transform: translateY(100%) scale(0);
    transition: opacity 0.2s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
}
#delete-popup-main {
    background: #fff;
    width: 50%;
    padding: 20px;
    border-radius: 5px;
    margin: 0 auto;
    position: relative;
}
.open-delete-acc {
   transform: translateY(0) scale(1) !important;
}
#delete-popup-inner {
   max-width: 600px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 10px 0 10px;
}
button.del-btn {
    padding: 8px 20px;
    margin-right: 12px;
    margin-top: 20px;
    border: 0;
    color: #fff;
    cursor: pointer;
}

#h1 {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 30px;
}

.realistic-marker-highlight{
    color: #ffffff;
    margin-left: 15px;
    font-weight: 500;
    z-index: 9;
    position: relative;
    font-size: 22px;
    vertical-align: text-bottom;
}
.realistic-marker-highlight:before{
    content: "";
    background-color: #00000087;
    width: 107%;
    height: 1.8em;
    position: absolute;
    z-index: -1;
    filter: url(#marker-shape);
    padding: 0;
    margin-left: -13px;
}

.navigation.navigation-mob {
    width: 50px;
    margin-top: 45px;
}
.navigation.navigation-mob ul li a .icon {
    position: relative;
    display: block;
    min-width: 30px;
    height: 43px;
    line-height: 60px;
    text-align: center;
}
.navigation.navigation-mob .toggle {
    width: 33px;
    height: 33px;
    bottom: 10px;
    right: 10px;
}
.navigation.navigation-mob .toggle::before {
  width: 17px;
}
.navigation.navigation-mob .toggle::after {
  width: 16px;
}
h1#mob-h1 {
    top: 32px;
    position: absolute;
    text-align: center;
    width: 100%;
}
h1#mob-h1 span.realistic-marker-highlight {
    font-size: 16px;
}
div#forgot-pass {
    text-align: right;
    font-size: 12px;
    color: #fff;
}
div#forgot-pass span {
    cursor: pointer;
}

/*****Modern Content loading effect******/
.main-item {
  padding: 10px;
  background-color: none;
  width: 100%;
  border-radius: 4px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -300px 0
  }
  100% {
    background-position: 300px 0
  }
}

.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background : linear-gradient(90deg, rgb(0 0 0 / 19%) 8%, #e3e3e300 50%, rgba(0, 0, 0, .23));
    background-size: 100% 30px;
    height: 19px;
    position: relative;
    margin-top: 10px;
    border-radius: 4px;
}

.MuiAccordionSummary-content p {
    width: 100%;
}
.navigation-mob ul {
    left: 5px;
}

@media screen and (max-height: 650px) {
   div#pro-popup-main {
         padding: 0px;
   }
   div#second-pro ul {
    list-style: none;
    margin: 6px auto 0px;}
    div#second-pro ul li {
    font-size: 16px;
    margin-bottom: 5px;
   }
   div#second-pro button {
    padding: 0px;
    width: 236px;
    margin-top: -6px;
    height: 42px;
}
p#powered-by {
    font-size: 13px;
    margin-top: 5px;
}

}
.user-info button {
    padding: 5px 20px;
    background: #fff;
    border: 0;
    border-radius: 4px;
}
.user-info button {
    padding: 8px 0;
    background: #fff;
    border: 0;
    border-radius: 4px;
    width: 85px;
}
.user-info button svg {
    font-size: 17px;
        color: #000;
}
/******slate********/
div#InputWrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
}
div#placeholder {
    color: #c0c0c0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 25px;
    padding-top: 0;
    height: calc(100% - 72px);
}
div#placeholder h4 {
    font-size: 18px;
}
div#inputText p:first-child span {

}
/******slate********/

div#result p {
    margin-bottom: 12px;
}
div#result h1, div#result h2, div#result h3, div#result h4, div#result h5 {
    margin-bottom: 20px;
}
div#result ul, div#result ol {
    margin-left: 25px;
    margin-top: 10px;
    margin-bottom: 15px;
}
div#inputText ul, div#inputText ol {
    margin-left: 20px;
    margin-bottom: 20px;
}
ul#topbar-ul {
    margin-top: -21px;
    font-size: 13px;
    color: #fff;
    margin-left: 290px;
}
ul#topbar-ul li {
    display: inline;
    margin-left: 10px;
}
ul#topbar-ul li svg {
    font-size: 16px !important;
    vertical-align: text-top;
    cursor: initial;
}
div#inputText span {

}
div#inputText p:first-child {
    margin: 0 !important;
}
span.quota-col svg {
    font-size: 15px;
    vertical-align: text-top;
    margin-right: 3px;
}
span.quota-col {
    background: rgb(0 0 0 / 35%);
    margin-right: 5px;
    padding: 10px 5px;
    text-align: center;
}
span.quota-col span.tooltip span.tooltiptext {
    width: 200px;
    left: 0;
}
span.quota-col span.tooltip svg {
    font-size: 11px;
    color: #666;
    margin-top: 3px;
    margin-left: 4px;
}
span.quota-col .tooltip .tooltiptext::after {
    content: "";
    left: 34%;
}

span.quota-col:nth-child(1) {
    width: 32%;
}
span.quota-col:nth-child(2) {
    width: 22%;
}
span.quota-col:nth-child(3) {
    width: 40%;
}
span.quota-col.tos span {
    font-size: 10px;
}
span.quota-col.tos span a {
    text-decoration: none;
    font-weight: 600;
}
input[name="tos"] {
    margin-right: 5px;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  display: inline-block;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

   span.quota-col svg {
    display: none;
  }
  
@media screen and (min-width: 800px) and (max-width: 1000px) {
    .colors { display: none; }
    span.quota-col:nth-child(3) {
      width: 62%;
    }
    span.quota-col:nth-child(2) {
      display: none;
    }
    .navigation {
      position: fixed;
      inset: 0;
      width: 55px;
    }
    .navigation ul li a .icon {
       min-width: 48px;
    }
    ul#topbar-ul li {
    display: inline;
    margin-left: 6px;
    font-size: 12px;
   }
   div#discount-top p {
       font-size: 12px;
   }
   span.quota-col svg {
    display: none;
  }
  span#quota {
    font-size: 11px;
  }

}
canvas {
    position: fixed;
    bottom: 100px;
    left: 0;
    z-index: 99999999;
    width: 100%;
    height: 100vh;
}

.apply_button {
    width: 70px !important;
    height: 32px !important;
    margin-left: 10px;
    background: #4a4a4a !important;
    font-size: 13px !important;
        color: #fff;
    border: 0;
    border-radius: 4px;
}
.apply_button.active {
    background: #8b8787 !important;
    cursor: no-drop !important;
}
p#resend-verification {
    font-size: 12px;
    text-align: center;
    margin: 13px 0;
    text-decoration: underline;
}
p#resend-verification span {cursor: pointer;}
p#lifetime_message {
    font-size: 15px;
    text-align: center;
    margin-top: 25px;
}
p#message_extra, p#add_another {
    font-size: 14px;
    color: #555;
    margin-top: 20px;
    text-align: center;
}
#TextBottomRight {
    float: left !important;
    font-size: 13px;
    margin-top: 5px;
        margin-left: 20%;
}
#TextBottomRight span {
    margin-left: 2px;
    font-weight: 600;
    cursor: pointer;
}
#documentation {
    position: absolute;
    right: 45px;
    top: 13px;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
    color: #fff;
}
#documentation svg {
    font-size: 16px;
    color: #fff;
    vertical-align: sub;
}
ol.my-ol {
    padding-left: 36px;
    margin-top: 10px;
}
ol.my-ol li {
    line-height: 32px;
}
ul.my-ul {
    padding-left: 42px;
    margin-top: 10px;
}
ul.my-ul li {
    line-height: 32px;
}
div#result p:first-child {
    margin: 0 !important;
}

#pricing {
    text-align: center;
}

#pricing ul {
       list-style: none;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px;
}
#pricing ul li {
    margin-bottom: 8px;
    font-size: 16px;
        color: #fff;
}
#pricing ul li svg {
    font-size: 18px;
    vertical-align: sub;
    color: #fff;
}
.container-pricing {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.table-container {
    flex: 1;
    min-width: calc(50% - 20px);
    margin: 10px;
    background-color: rgb(0 0 0 / 12%);
    padding: 20px;
    box-sizing: border-box;
}
.pricing-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.pricing-table thead tr {
    background-color: rgba(0,0,0,0.54);
    color: #ffffff;
    text-align: center;
}
.pricing-table thead tr:first-child {
    background-color: unset;
    font-size: 17px;
}
.pricing-table th, .pricing-table td {
   padding: 12px 15px;
   color: #d1d1d1;
}
.pricing-table thead tr:first-child th {
    color: #fff;
}
.pricing-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.pricing-table tbody tr:nth-of-type(even) {
    background-color: rgba(0,0,0,0.16);
}

.pricing-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

/* RadioButtonStyles.css */
.custom-radio {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  margin-right: 8px;
  cursor: pointer;
}

.custom-radio::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

input[type="radio"]:checked + .custom-radio::after {
  transform: translate(-50%, -50%) scale(1);
}
label.radio-label {
    position: relative;
    margin-left: 10px;
}
label.radio-label input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
div#settings-update {
    box-shadow: 1px 1px 7px 2px #dcd7d7;
    margin-bottom: 22px;
    margin-left: 12px;
    background: #fff;
    border-left: 4px solid #8BC34A;
    padding: 10px;
    position: fixed;
    top: 0;
    right: 76px;
}
div#settings-update svg {
    color: #009688;
    vertical-align: sub;
}
.setting-close svg {
    cursor: pointer;
    background: #000;
    font-size: 19px;
    border-radius: 50%;
    color: #fff !important;
    margin-left: 20px;
    padding: 2px;
}
div#description {
    max-width: 1000px;
    margin: 0 auto;
    background: rgb(0 0 0 / 30%);
    padding: 30px;
    color: #fff;
    font-size: 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    line-height: 28px;
    margin-top: 40px;
}
div#description svg {
    font-size: 70px;
    margin-right: 30px;
}
.MuiAccordion-root {
    background-color: rgb(0 0 0 / 12%) !important;
}
.MuiAccordionSummary-content p h4 {
    font-size: 16px;
}
.css-i4bv87-MuiSvgIcon-root {
    color: #fff;
}
.css-15v22id-MuiAccordionDetails-root {
    padding: 8px 16px 16px;
    color: #dadada;
}
img.newlogo {
    width: 275px;
}
div#offcanvasNavbar {
    z-index: 999999999;
}




/*********mobile responsiveness code*******/
body.bg-salmon {
    overflow: unset;
}
body.bg-salmon div#discount-top p {
    font-size: 10px;
    padding: 0 27px;
}
#body-mobile {
    min-height: 100vh;
    transition: 0.5s;
    align-items: center;
}
.navigation.navigation-mob {
    display: none;
}
h1#mob-h1 {
    text-align: center;
    width: 100%;
    background: #000;
}
h1#mob-h1 span.realistic-marker-highlight {
    font-size: 16px;
    vertical-align: middle;
}
div#tool-mobile div#row {
    display: block;
    margin: 0 auto;
    width: 100%;
    box-shadow: unset;
    position: relative;
}
div#tool-mobile #top-bar {
    width: 100%;
}
div#tool-mobile #left-column, div#tool-mobile #right-column {
    border-right: 0 !important;
    border-left: 0 !important;
}
div#tool-mobile div#bottom-left {
    position: static;
    background: rgba(0,0,0,0.34);
    float: none;
    width: 100%;
    padding: 10px;
        display: block;
}
div#tool-mobile span#quota {
    font-size: 12px;
    display: flex;
    position: static;
    width: 100%;
}
div#tool-mobile p.mytext-res {
    position: static !important;
}
div#tool-mobile button#paraphrase {
    width: 100%;
    position: static;
}
div#tool-mobile .paraphrase-loggedin {
   margin-top: 15px;
}
div#tool-mobile p#small-text-left-bottom {
    position: static;
    font-size: 11px;
    margin-bottom: 13px;
}
span#mobile-menu {
    float: right;
    margin-right: 10px;
    cursor: pointer;
}
span#mobile-menu svg {
    color: #fff;
}

.nav-mobile {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 99999;
}
.nav-mobile-hide {display: none;}
.nav-mobile ul {
    margin: 37px 20px 20px 30px;
    list-style: none;
}
.nav-mobile ul li {
    margin-bottom: 23px;
}
.nav-mobile ul li a {
    text-decoration: none;
}
span#close-mobile-menu {
    position: fixed;
    top: 12px;
    right: 12px;
    cursor: pointer;
}
div#tool-mobile div#inputText {
    min-height: unset;
    max-height: unset;
    margin-bottom: 0;
    height: calc(100vh - 294px);
}
div#tool-off {
    display: none;
}
div#tool-mobile {
    overflow-x: hidden;
}

@media screen and (max-width: 1250px) {
   div#pro-popup-main {
          width: 70%;
   }
}
@media screen and (max-width: 1150px) {
   div#pro-popup-main {
          width: 80%;
   }
      div#mainRow {
     display: block;
   }
}
@media screen and (max-width: 1050px) {
   div#pro-popup-main {
          width: 90%;
   }
}
@media screen and (max-width: 767px) {
        .mobile-full-btn {
    display: block !important;
}
.mobile-full-btn button {
    width: 100% !important;
    margin-bottom: 15px;
}
.plan-amnt span:nth-child(2) {
    font-size: 20px !important;
    vertical-align: unset !important;
}
button.position-change {
    position: static !important;
}

div#right-bottom {
    position: static;
}
div#success-inner {
    display: block;
}
.success-col.right-side {
    display: none;
}
  p#powered-by {
    padding-bottom: 30px;
  }
  div#description {
    max-width: 1000px;
    margin: 0 15px;
    background: rgb(0 0 0 / 30%);
    padding: 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 12px;
    display: block;
    line-height: 24px;
    margin-top: 40px;
  }
  div#description svg {
    display: none;
  }
    .card {
       margin: 0 12px;
    }
    h4#myhead {
       font-size: 16px;
       margin-bottom: 0;
    }
    .page-content {
       padding: 0.5rem 0;
    }
    .switcher-btn {
      display: none;
    }
    .column {
      padding: 12px 0px 0px 0px;
    }
    span.quota-col:nth-child(3) {
      width: 44%;
      margin-right: 0;
    }
    div#right-bottom span.tooltip {
       padding: 0;
    }
    #TextBottomRight {
      font-size: 12px;
      margin-top: 5px;
      margin-left: 2px;
   }
   div#inputText {
      padding: 0 20px 0 16px;
   }
   div#pro-popup {
      position: fixed;
      bottom: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.9);
      z-index: 999999999;
      transition: opacity 0.2s ease, transform 0.2s ease;
      display: flex;
      align-items: center;
      overflow-y: scroll;
      top: 0;
   }
   div#pro-popup-main {
      width: 90%;
   }
   div#plans {
     display: block;
     margin-bottom: 25px;
   }
   div#second-pro ul li {
      font-size: 13px;
      margin-bottom: 5px;
   }
   div#pro-popup-main h3 {
      font-size: 17px;
      margin-bottom: 14px;
          margin-top: 20px;
   }
   .innerAcc {
    width: 100% !important;
    overflow-y: unset !important;
    height: unset !important;
    padding: 10px !important;
   }

   .column-inner {
     width: 100% !important;
     margin-bottom: 30px;
     padding-left: 0px !important;
   }
   .MuiAccordionSummary-content p h4 {
      font-size: 14px;
   }
   .user-box .user-info {
      display: block !important;
   }
   .offcanvas.showing {
      visibility: visible;
      z-index: 999999;
    }
    .sidebar ul {
    list-style: none;
    background: rgba(0, 0, 0, 0.30);
    height: auto !important;
    position: static !important;
    width: 100% !important;
    padding-left: 0;
   }
   div#mycol-main {
    display: block !important;
  }
  div#mycol-main .mycol-1 {
    width: 100%;
    border-right: unset !important;
  }
  .container-pricing {
    padding: 4px;
    display: block;
  }
  .table-container {
    padding: 15px 0;
    margin-bottom: 38px;
   }
}

@media screen and (max-width: 420px) {

   #mode-title svg {
    display: none;
  }
  .realistic-marker-highlight {
    color: #ffffff;
    margin-left: 0px;
    font-weight: 500;
    z-index: 9;
    position: relative;
    font-size: 18px;
    vertical-align: text-bottom;
  }
  .realistic-marker-highlight:before {
    content: "";
    background-color: #00000087;
    width: 107%;
    height: 2.1em;
    position: absolute;
    z-index: -1;
    filter: url(#marker-shape);
    padding: 0;
    margin-left: -10px;
  }
}

#before_result {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}
#before_result span {
    margin-top: 20%;
    display: inline-block;
}
