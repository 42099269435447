
.form-main {
  width: 100%;
  border-radius: 5px;
  text-align: center;
  padding: 50px 35px 10px 35px;
}

.progress-bar-multi {
  display: flex;
}

.step {
    text-align: center;
    width: 100%;
    position: relative;
}

.step p {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.bullet {
  height: 40px;
  width: 40px;
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  line-height: 38px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.bullet.active {
    background: #fff;
    color: #000;
}

form {
  display: flex;
  flex-direction: column;
}

.page {
  display: none;
  padding: 15px;
}

.page.active {
  display: block;
}

.field {
  margin: 20px 0;
}

.field input {
  width: 100%;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  font-size: 18px;
}
.field.btns {
    display: flex;
    gap: 20px;
}
.field button {
    width: 50%;
    padding: 10px;
    border: none;
    background: rgb(0 0 0 / 71%);
    color: #eeeeee;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: auto;
}

/*****website table*****/
table {
  width: 100%;
  border: 1px solid;
  border-collapse: collapse;
}
th, td {
  border: 1px solid;
  padding: 12px;
}
th:first-child {
  width: 25px;
}
td {
  text-align: left;
}

/****anchor text css****/
.anchor-main {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}
table.anchor-table td {
  width: 50%;
}
table.anchor-table td input[type="text"] {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background: rgba(0, 0, 0, 40%);
  border: none;
  border-radius: 5px;
  color: #fff;
}
.add_more_link {
  padding: 5px 10px;
  border-radius: 6px;
}
.add_more_link svg {
  color: #000;
}

/*****articles css****/
.articles {
  text-align: left;
  margin-top: 20px;
}
.articles ul {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}
.articles ul li {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 14px;
  border-bottom: 1px solid;
}
.popup-article {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 70%);
  display: flex;
  align-items: center;
  z-index: 99999999999;
}
.popup-article-content {
  max-width: 1000px;
  padding: 30px;
  background: #fff;
  color: #000;
  margin: 0 auto;
  position: relative;
  text-align: left;
}
.popup-article-content h1, 
.popup-article-content h2, 
.popup-article-content h3, 
.popup-article-content h4, 
.popup-article-content h5, 
.popup-article-content h6  {
  color: #000;
}
.popup-article-content a {
  color: #2196F3;
  text-decoration: underline;
}
.popup-article-close {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}
.popup-article-close svg {
  color: #000;
  font-size: 32px;
}
/****website checkbox****/
.checkbox-wrapper-46 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.checkbox-wrapper-46 .cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.checkbox-wrapper-46 .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-46 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098A9;
  transition: all 0.2s ease;
}
.checkbox-wrapper-46 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-46 .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #506EEC;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.checkbox-wrapper-46 .cbx span:last-child {
  padding-left: 8px;
}
.checkbox-wrapper-46 .cbx:hover span:first-child {
  border-color: #506EEC;
}

.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child {
  background: #506EEC;
  border-color: #506EEC;
  animation: wave-46 0.4s ease;
}
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave-46 {
  50% {
    transform: scale(0.9);
  }
}
